<template>
  <v-dialog
    v-model="dialog"
    width="480px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        fab
        color="pink"
        dark
        v-bind="attrs"
        v-on="on"
        @click="screenshare"
      >
        <v-icon>mdi-monitor-share</v-icon>
      </v-btn>
    </template>
    <div id="screenshareContainer"></div>
  </v-dialog>
</template>

<script>
export default {
  name: 'Screenshare',
  data: () => ({
    dialog: false,
    config: {
      webchatAppUrl: 'https://apps.mypurecloud.jp/webchat',
      webchatServiceUrl: 'https://realtime.mypurecloud.jp:443',
      orgId: 68,
      orgName: 'ncnljapan',
      logLevel: 'DEBUG',
      locale: 'ja',
      orgGuid: "f08a0fa3-c061-4634-9b86-9ed63fcf486f",
      cssClass: "screenshare-frame",
      css: {
          "width": "100%",
          "height": "280px",
          "border": "none"
      },
      contentCssUrl: "screenshare.css",
      standAloneApplication: false,
      webchatDeploymentKey: "e86d2daa-ba98-4f80-aaa9-9be1eafd8236"      
    }
  }),
  methods: {
    screenshare() {
      // eslint-disable-next-line
      ININ.screenshare.create(this.config, function(err, screenshare) {
        if (err) {
          // You should change this block to properly handle errors.
          console.error(err);
          if (err.name === 'UNSUPPORTED_BROWSER') {
            // Redirect to instructions for unsupported browser, or handle appropriately
            alert('お使いのブラウザはサポートされていないか、暗号化された通信ではありません。');
            return;
          }
          alert('画面共有ウィジェットを開くのにエラーが発生しました。 コンソールログをご確認ください。');
        }
        screenshare.renderScreenShareForm({
            containerEl: 'screenshareContainer'
        });
      });
    }
  }
}
</script>