<template>
  <v-app>
    <navbar-app />
    <v-main>
      <router-view></router-view>
    </v-main>
    <footer-app />
    <button-app />
    <snackbar-app />
  </v-app>
</template>

<script>
import db from '@/firebase/init'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'


import Navbar from '@/components/layout/Navbar';
import Button from '@/components/Button';
import Snackbar from '@/components/Snackbar'
import Footer from '@/components/layout/Footer';

export default {
  name: 'App',
  components: {
    'navbar-app': Navbar,
    'button-app': Button,
    'snackbar-app': Snackbar,
    'footer-app': Footer
  },
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if(user) {
        this.$store.dispatch('isLoggedIn', true)
        console.log('isLoggedIn', true);
        db.collection('users').where('userId','==',user.uid).get()
        .then(snapshot => {
          snapshot.docs.forEach(doc => {
            this.$store.dispatch('user', doc.data())
          })
        })
      }
    })
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'AIR GENESYS';
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;500;600;700&family=Kosugi+Maru&display=swap');

.v-application {
  font-family: 'Josefin Sans', 'Kosugi Maru', sans-serif !important;
}
</style>