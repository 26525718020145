import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
    apiKey: "AIzaSyBsuqAcGGUHeFvvuIcslFpo7lXB3IYNpuI",
    authDomain: "airgenesys.firebaseapp.com",
    projectId: "airgenesys",
    storageBucket: "airgenesys.appspot.com",
    messagingSenderId: "471247052516",
    appId: "1:471247052516:web:81cdc70c738b64c3d516f1",
    measurementId: "G-2CW0EKHRFK"
};
// Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// firebase.analytics();
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp.firestore()