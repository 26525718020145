<template>
	<v-card
	class="pa-5">
		<v-card-title
		class="justify-center">{{ status }}</v-card-title>
		<v-card-text>
			<v-form
			ref="form"
			lazy-validation>
				<v-row>
					<v-col
					cols="6">
						<v-text-field
							label="姓" 
							v-model="user.lastName"
							:rules="[rules.required]"
              :disabled="disabled"              
							required></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field 
							label="名" 
							v-model="user.firstName"
							:rules="[rules.required]"
              :disabled="disabled"
							required></v-text-field>                  
					</v-col>

					<v-col
						cols="12">
						<v-text-field 
							label="メールアドレス"
							v-model="user.email"
							:rules="[rules.required, rules.email]"
              :disabled="disabled"
							required></v-text-field>
					</v-col>
					<v-col
					cols="12">
						<v-select
						label="問い合わせ種別" 
						:items="items" 
						:rules="[rules.required]"
						v-model="queueName"
						:disabled="disabled"
						required></v-select>            
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>			
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				dark
				color="success"
				tile
				@click="videochat">
				開始
				</v-btn>
		</v-card-actions>
      <p id="response">
      <a 
      v-if="responseUrl"
      :href="responseUrl" 
      target="videoengageriframe" 
      class="blink_me">Accept Incoming Video Chat</a></p>
    <iframe v-if="showFrame" width="100%" height="400" id="videoengageriframe" :src="loadUiUrl" allow="microphone; camera;"></iframe>

	</v-card>
</template>

<script>
import platformClient from 'purecloud-guest-chat-client/src/purecloud-guest-chat-client'
const client = platformClient.ApiClient.instance
const webChatApi = new platformClient.WebChatApi()

export default {
  name: 'VideoChat',
	props: ['oWaiting','onQueueUsers'],
  data: () => ({
    status: null,
		disabled: true,
    showFrame: false,
		items: [
			{text: '予約確認・変更', value: '01_予約確認・変更'},
			{text: '忘れ物・手荷物破損', value: '02_忘れ物・手荷物破損'},
			{text: '当日の発着案内', value: '03_発着案内'},
			{text: 'WEB操作にお困りの方', value: '04_WEB操作'},
			{text: 'その他', value: '05_その他'}
		],
    rules: {
			required: v => !!v || '必須',
			email: v => /.+@.+/.test(v) || 'メールアドレスが不正です。',
		},
    queueName: '',
    organizationId: 'f08a0fa3-c061-4634-9b86-9ed63fcf486f',
    deploymentId: 'e86d2daa-ba98-4f80-aaa9-9be1eafd8236',
    veUrl: 'https://videome.leadsecure.com',
    tenantId: 'fR8tnqVNy6FixNcC',
    video_on: false,
    returnExtendedResponses: false,
    enableDebugLogging: false,
    chatId: '',
    memberId: '',
    jwt: '',
    interactionId: '',
    loadUiUrl: '',
    responseUrl: ''
  }),
  methods: {
    configureSDK() {
      client.setEnvironment(platformClient.PureCloudRegionHosts.ap_northeast_1);
      client.setReturnExtendedResponses(this.eturnExtendedResponses);
      if (this.enableDebugLogging) {
        client.setDebugLog(console.log);
      }
    },
    setInteractionId() {
      this.interactionId = this.getCookie("interactionId");
      console.log("setInteractionId-1: ", this.interactionId)
        if (this.interactionId == undefined) {
          this.interactionId = this.getGuid();
          console.log("setInteractionId-2: ", this.interactionId)
          this.setCookie("interactionId", this.interactionId, 24);
      }
    },
    getCookie: function(name) {
      var pattern = new RegExp(name + "=.[^;]*");
      var matched = document.cookie.match(pattern);
      if (matched) {
        var cookie = matched[0].split("=");
        var cooki = decodeURIComponent(cookie[1]).replace(/"/g, "");
          console.log("getCookie: ", cooki)
          return cooki;
        }
      return null;
    },
    getGuid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }
      return (
        s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4()
      );
    },
    setCookie: function(name, value, hour) {
      var cookieName = name;
      var cookieValue = value;
      var d = new Date();
      var time = d.getTime();
      var expireTime = time + 1000 * 60 * 60 * parseInt(hour);
      d.setTime(expireTime);
      if (hour) {
        document.cookie = cookieName + "=" + cookieValue + ";expires=" + d.toGMTString() + ";path=/";
      } else {
        document.cookie = cookieName + "=" + cookieValue + ";path=/";
      }
    },
    videochat() {
      this.showFrame = !this.showFrame
      const createChatBody = {
        organizationId: this.organizationId,
        deploymentId: this.deploymentId,
        routingTarget: {
            targetType: "QUEUE",
            targetAddress: this.queueName
        },
        memberInfo: {
          displayName: this.user.lastName + ' ' +  this.user.firstName,
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          email: this.user.email
        }
      };
      // Create chat
      webChatApi.postWebchatGuestConversations(createChatBody)
      .then(createChatResponse => {
        let chatInfo = createChatResponse.body ? createChatResponse.body : createChatResponse;
        client.setJwt(chatInfo.jwt);
        let socket = new WebSocket(chatInfo.eventStreamUri);
        this.chatId = chatInfo.id;
        this.memberId = chatInfo.member.id;
        this.jwt = chatInfo.jwt;
        // Listen for messages
        socket.addEventListener("message", this.onReceivedMessageEventFromSocket);
      })
      .catch(err => {
         console.error(err);
      });
    },
    onReceivedMessageEventFromSocket: function(event){
      console.log("onReceivedMessageEventFromSocket: ", event);
      const message = JSON.parse(event.data);
      console.log("message: ", message);
      if (message.metadata) {
        switch (message.metadata.type) {
          case 'message': {
            this.onReceivedMessageFromConversation(message);
            break;
          }
          case 'member-change': {
            console.log("member-change called1", message.eventBody);
            console.log("member-change called2", message.eventBody.member.id);
            console.log("member-change called3", this.memberId);
            console.log("member-change called4", message.eventBody.member.id === this.memberId);
          if (message.eventBody && message.eventBody.member.id === this.memberId) {
            console.log("member-change called5", this.interactionId);
            this.onConnected(this.interactionId);
          }
          break;
          }
        }
      }
    },
    onReceivedMessageFromConversation: function(data) {
      console.log("onReceivedMessageFromConversation: ", data)
      if (data.eventBody && data.eventBody.body && data.eventBody.body.indexOf(this.veUrl) !== -1) {
        this.responseUrl = data.eventBody.body;
        //console.log('onReceivedMessageFromConversation: ', this.responseUrl);
      }
    },
    onConnected() {
      this.sendInteractionId(this.chatId, this.memberId, this.interactionId);
      this.loadUI(this.veUrl, this.interactionId, this.tenantId);
    },
    sendInteractionId() {
      let data = {
        body: `{"interactionId": "${this.interactionId}"}`
      }
      console.log('sendInteractionId: ', data);
      // Set JWT
      console.log("Set JWT: ", this.jwt);
      client.setJwt(this.jwt)
      // Send a Message
      webChatApi.postWebchatGuestConversationMemberMessages(this.chatId, this.memberId, data)
      .then(() => {
        //console.log(`postWebchatGuestConversationMemberMessages success! data: ${JSON.stringify(data, null, 2)}`);
      })
      .catch((err) => {
        console.log('There was a failure calling postWebchatGuestConversationMemberMessages');
        console.error(err);
      });
    },
    loadUI() {
      //console.log('loadUI!')
      let str = {
        video_on: this.video_on,
        sessionId: this.interactionId,
        hideChat: true,
        type: "initial",
        defaultGroup: "floor",
        view_widget: "4",
        offline: true,
        aa: true,
        inichat: "false"
      };
      console.log("loadUI str: ", str)
      let encodedString = window.btoa(JSON.stringify(str));
      this.loadUiUrl = `${this.veUrl}/static/popup.html?tennantId=${window.btoa(this.tenantId)}&params=${encodedString}`;
      console.log('loadUI URL: ', this.loadUiUrl)
    }
  },
  created() {
		if(this.onQueueUsers == 0) {
			this.status = '現在、受付可能なエージェントがおりません'
		} else {
			this.disabled = false
      //console.log('oWaiting: ', this.oWaiting)
			if(this.oWaiting != null) {
				this.status = '現在の待ち: ' + this.oWaiting + '人'
			} else {
				this.status = '現在の待ち: 不明'
			}
    }
  },
  mounted() {
    this.configureSDK()
    this.setInteractionId()
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  }
}
</script>