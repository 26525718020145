<template>
  <div class="contact pa-5">
    <v-container>
      <v-row
      justify="center">
        <v-col
        cols="12"
        lg="6"
        md="8">
          <v-card>
            <v-card-title class="title text-center justify-center py-6">お問い合わせ方法</v-card-title>
            <v-tabs
              v-model="tab"
              color="light-blue darken-4"
              fixed-tabs
              show-arrows>
              <v-tab
              v-for="(item,index) in items"
              :key="index">
                <v-icon>{{ item.icon }}</v-icon>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item>
                <email-tab />
              </v-tab-item>
              <v-tab-item>
                <chat-tab :oWaiting="oWaiting" :onQueueUsers="onQueueUsers" />
              </v-tab-item>
              <v-tab-item>
                <videochat-tab :oWaiting="oWaiting" :onQueueUsers="onQueueUsers" />
              </v-tab-item>
              <v-tab-item>
                <callback-tab />
              </v-tab-item>

            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>    
  </div>
</template>

<script>
import axios from 'axios'
import jsonpath from 'jsonpath'

import Email from '@/components/tabs/Email'
import Chat from '@/components/tabs/Chat'
import VideoChat from '@/components/tabs/VideoChat'
import Callback from '@/components/tabs/Callback'

export default {
  name: 'Contact',
  components: {
    'chat-tab': Chat,
    'videochat-tab': VideoChat,
    'email-tab': Email,
    'callback-tab': Callback,
  },
  data: () => ({
    onQueueUsersArr: [],
    onQueueUsers: null,
    oWaitingArr: [],
    oWaiting: null,
    tab: null,
    items: [
      {icon: 'mdi-email'},
      {icon: 'mdi-chat'},
      {icon: 'mdi-video-outline'},
      {icon: 'mdi-phone'}
    ],
    menuDate: false,
    menuTime: false,
    user: '',
    form: {
      type: '',
      date: '',
      time: '',
      subject: '', 
      message: '',
    },
    feedback: null
  }),
  async created() {
    let res = await axios.get('https://asia-northeast1-airgenesys.cloudfunctions.net/postAnalyticsQueuesObservationsQuery');
    console.log(res);
    this.onQueueUsersArr.push(jsonpath.query(res.data, `$..data[?(@.metric == "oOnQueueUsers" && @.qualifier == "IDLE")].stats.count`).length)
    this.onQueueUsersArr.push(jsonpath.query(res.data, `$..data[?(@.metric == "oOnQueueUsers" && @.qualifier == "COMMUNICATING")].stats.count`).length)
    this.onQueueUsersArr.push(jsonpath.query(res.data, `$..data[?(@.metric == "oOnQueueUsers" && @.qualifier == "INTERACTING")].stats.count`).length)
    this.onQueueUsersArr.push(jsonpath.query(res.data, `$..data[?(@.metric == "oOnQueueUsers" && @.qualifier == "NOT_RESPONDING")].stats.count`).length)
    console.log('onQueueUsersArr: ', this.onQueueUsersArr)
    this.onQueueUsers = this.onQueueUsersArr.reduce((a,c) => a+=c, 0)
    console.log('onQueueUsers: ', this.onQueueUsers)
    this.oWaitingArr = jsonpath.query(res.data, `$..data[?(@.metric == "oWaiting")].stats.count`)
    console.log('oWaitingArr: ', this.oWaitingArr)
    this.oWaiting = this.oWaitingArr.reduce((a,c) => a+=c, 0)
    console.log('oWaiting: ', this.oWaiting)
  }
}
</script>

<style>
.contact {
  width: 100vw;
  min-height: 100vh;
  background-image: url('/images/cover_02.jpg');
  background-size: cover;
  background-attachment: fixed;
}
</style>