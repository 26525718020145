import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)
Vue.use(VueRouter)

export default new Vuex.Store({
  state: {
    user: {
      address: '',
      email: '',
      birthDate: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      postalCode: '',
      userId: '',
      userName: '',
      cardName: '',
      cardNumber: '',
      cardExpire: '',
      cardSecurityCode: ''  
    },
    isLoggedIn: false,
    checked: [],
    results: [],
    items: [],
    queues: [],
    callbackQueues: [],
    snackbar: {
      show: false,
      text: 'null'
    }
  },
  mutations: {
    user: (state, payload) => {
      state.user = payload;
      console.log('mutations user: ', state.user)
    },
    isLoggedIn: (state, payload) => {
      state.isLoggedIn = payload;
      console.log('mutations isLoggedIn: ', state.isLoggedIn)
    }
  },
  actions: {
    user: (context, payload) => {
      context.commit('user', payload)
    },
    isLoggedIn: (context, payload) => {
      context.commit('isLoggedIn', payload)
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    isLoggedIn(state) {
      return state.isLoggedIn
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState({ storage: window.sessionStorage })
  ]
})
