import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/compat/app'

import Home from '@/views/Home.vue'
import Register from '@/views/Register'
import Login from '@/views/Login'
import Profile from '@/views/Profile'
import Contact from '@/views/Contact'

Vue.use(VueRouter)

const DEFAULT_TITLE = 'AIR GENESYS | Genesys Cloud Demo Site V2';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: '新規会員登録 | AIR GENESYS'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '会員ログイン | AIR GENESYS'
    }    
  },  
  {
    path: '/profile/:id',
    name: 'Profile',
    component: Profile,
    meta: {
      title: '会員情報 | AIR GENESYS',
      requiresAuth: true
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: {
      title: 'お問い合わせ | AIR GENESYS'
    }  
  },
  {
    path: '/flight',
    name: 'Flight',
    component: Home,
    meta: {
      title: 'フライト | AIR GENESYS'
    }  
  },
  {
    path: '/tour',
    name: 'Tour',
    component: Home,
    meta: {
      title: 'ツアー | AIR GENESYS'
    }  
  },
  {
    path: '/hotel',
    name: 'Hotel',
    component: Home,
    meta: {
      title: 'ホテル | AIR GENESYS'
    }  
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from, next) => {
  if(to.matched.some(rec => rec.meta.requiresAuth)) {
    let user = firebase.auth().currentUser
    if(user) {
      next()
    } else {
      next({ name: 'Login'})
    }
  } else {
    next()
  }
});
router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router