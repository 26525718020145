<template>
	<v-card
	class="pa-5">
		<v-card-title
		class="justify-center">{{ status }}</v-card-title>
		<v-card-text>
			<v-form
			ref="form"
			lazy-validation>
				<v-row>
					<v-col
					cols="6">
						<v-text-field
							label="姓" 
							v-model="user.lastName"
							:rules="[rules.required]"
							:disabled="disabled"
							required></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field 
							label="名" 
							v-model="user.firstName"
							:rules="[rules.required]"
							:disabled="disabled"
							required></v-text-field>                  
					</v-col>

					<v-col
						cols="12">
						<v-text-field 
							label="メールアドレス"
							v-model="user.email"
							:rules="[rules.required, rules.email]"
							:disabled="disabled"
							required></v-text-field>
					</v-col>
					<v-col
					cols="12">
						<v-select
						label="問い合わせ種別" 
						:items="items" 
						:rules="[rules.required]"
						v-model="chatConfig.queueName"
						:disabled="disabled"
						required></v-select>            
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>			
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				dark
				color="light-blue darken-4"
				tile
				@click="chat"
				:disabled="disabled">
				開始
				</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: 'ChatTab',
	props: ['oWaiting','onQueueUsers'],
	data: () => ({
		status: null,
		disabled: true,
		rules: {
			required: v => !!v || '必須',
			email: v => /.+@.+/.test(v) || 'メールアドレスが不正です。',
		},
		items: [
			{text: '予約確認・変更', value: '01_予約確認・変更'},
			{text: '忘れ物・手荷物破損', value: '02_忘れ物・手荷物破損'},
			{text: '当日の発着案内', value: '03_発着案内'},
			{text: 'WEB操作にお困りの方', value: '04_WEB操作'},
			{text: 'その他', value: '05_その他'}
		],
		chatConfig: {
			webchatAppUrl: 'https://apps.mypurecloud.jp/webchat',
			webchatServiceUrl: 'https://realtime.mypurecloud.jp:443',
			orgId: '68',
			orgName: 'ncnljapan',
			logLevel: 'DEBUG',
			locale: 'ja',
			queueName: '',
			data: {
				//displayName: '',
				firstName: '',
				lastName: '',
				email: ''
			},
			companyLogo: {
				width:600,
				height: 149,
				url: 'https://okanoagentkartedemo01-uift.web.app/images/companyLogo.png'
			},
			companyLogoSmall: {
				width:25,
				height: 25,
				url: 'https://okanoagentkartedemo01-uift.web.app/images/companyLogoSmall.png'
			},
			// Text displayed with chat window is displayed
			welcomeMessage: 'オペレーターとお繋ぎしています。そのまましばらくお待ち下さい。',
			cobrowseConfig: {
				deploymentKey: 'D2Libt3kkxrawRTvE6fLihZK6IBL2WHD'
			}
		}	
	}),
	methods: {
    chat() {
			if(this.$refs.form.validate()) {
				//this.chatConfig.data.displayName = this.user.lastName + " " + this.user.firstName
				this.chatConfig.data.firstName = this.user.lastName
				this.chatConfig.data.lastName = this.user.firstName
				this.chatConfig.data.email = this.user.email
        // eslint-disable-next-line
				ININ.webchat.create(this.chatConfig, function(err, webchat) {
					if (err) {
							console.error(err);
							throw err;
					}
					webchat.renderPopup({
							width: 400,
							height: 600,
							title: 'チャット',
							newTab: false
					});
				});
			} else {
				this.feedback = 'please fill all fields.'
			}
		}
	},
	created() {
		if(this.onQueueUsers == 0) {
			this.status = '現在、受付可能なエージェントがおりません'
		} else {
			this.disabled = false
      //console.log('oWaiting: ', this.oWaiting)
			if(this.oWaiting != null) {
				this.status = '現在の待ち: ' + this.oWaiting + '人'
			} else {
				this.status = '現在の待ち: 不明'
			}
		}
	},
  computed: {
    user() {
      return this.$store.state.user;
    }
  }
}
</script>