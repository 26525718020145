<template>
  <v-footer
  app
  absolute
  dark
  padless
  inset>
    <v-card
    flat
    tile
    width="100%"
    color="success text-center">
      <v-card-text>
        <v-btn
        v-for="icon in icons"
        :key="icon"
        class="mx-4"
        icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-text>
        <p>{{ new Date().getFullYear()}} - Developed by Genesys Cloud Service KK PS</p>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>

export default {
  name: 'Footer',
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-youtube',
      'mdi-instagram',
      'mdi-linkedin'
    ]
  })
}
</script>
