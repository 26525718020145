<template>
    <v-dialog
      v-model="dialog"
      width="480px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          color="cyan"
          dark
          v-bind="attrs"
          v-on="on"
          @click="startCobrowse"
        >
          <v-icon>mdi-open-in-app</v-icon>
        </v-btn>
      </template>
      <div id="cobrowse-security-form"></div>
    </v-dialog>
</template>

<script>
export default {
  name: 'CoBrowse',
  data: () => ({
    dialog: false,
    config: {
      webchatAppUrl: 'https://apps.mypurecloud.jp/webchat',
      webchatServiceUrl: 'https://realtime.mypurecloud.jp:443',
      orgId: 68,
      orgName: 'ncnljapan',
      logLevel: 'DEBUG',
      locale: 'ja',
      orgGuid: "f08a0fa3-c061-4634-9b86-9ed63fcf486f",
      cssClass: "webchat-frame",
      css: {
        "width": "100%",
        "height": "280px",
        "border": "none"
      },
      containerEl: "cobrowse-security-form",
      promptForSecurityKey: true,
      cobrowseConfig: {
        deploymentKey: "D2Libt3kkxrawRTvE6fLihZK6IBL2WHD"
      }     
    }
  }),
  methods: {
    startCobrowse() {
      // eslint-disable-next-line
      ININ.cobrowse.create(this.config)
      .then(function(result) {
        console.log(result)
      })
      .catch(function(err) {
        console.error(err)
      });
    }
  }
}
</script>