<template>
  <div>
    <v-navigation-drawer
    app
    v-model="drawer"
    color="white">
      <template>
        <v-list>
          <v-list-item>
            <v-list-item-content v-if="user.userId">
              <v-list-item-title>{{ user.lastName + ' ' + user.firstName }} さん</v-list-item-title>
              <v-list-item-subtitle v-text="user.email"></v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-content v-if="!user.userId">
              <v-list-item-title>ゲストさん</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
          v-if="user.userId">
            <v-btn 
            dark
            color="primary"
            block
            tile
            :to="{name: 'Profile', params: {id: user.userName }}">
            会員情報</v-btn>
          </v-list-item>

          <v-list-item>
            <v-btn 
            dark
            color="success"
            block
            tile
            v-if="user.userId" 
            @click="logout">ログアウト</v-btn>

            <v-btn
            dark
            color="success"
            block
            tile
            v-if="!user.userId"
            to="/login">ログイン</v-btn>
          </v-list-item>
        </v-list>
      </template>

      <v-divider></v-divider>
      <v-list>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :to="item.path">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title  v-text="item.title"></v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>    
    <v-app-bar
    app
    fixed
    dark
    color="primary"
    elevate-on-scroll
    >
      <v-app-bar-nav-icon
      @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title
      >AIR GENESYS</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
      icon
      class="mr-1"
      to="/">
        <v-icon>mdi-home</v-icon>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'Navbar',
  data: () => ({
    drawer: false,
    items: [
      { title: 'フライト', icon:'mdi-airplane', path: '/flight'},
      { title: 'ツアー', icon: 'mdi-bag-suitcase', path: '/tour'},
      { title: 'ホテル', icon: 'mdi-bed', path: '/hotel'},
      { title: 'お問い合わせ', icon: 'mdi-help-circle', path: '/contact'}
    ]
  }),
  methods: {
    async logout() {
      try {
        await firebase.auth().signOut();
        this.$router.push({name: 'Login'})
        for(let i of Object.keys(this.user)) {
          this.user[i] = null
        }
        this.$store.dispatch('user', this.user)
        this.$store.dispatch('isLoggedIn', false)
      } catch (err) {
        console.error(err);
      }
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }        
  }
}
</script>

<style scoped>
</style>