<template>
	<v-card
	class="pa-5">
		<v-card-title
		class="justify-center">24時間以内</v-card-title>
		<v-card-text>
			<v-form
			ref="form"
			lazy-validation>
				<v-row>
					<v-col
					cols="6">
						<v-text-field
							label="姓" 
							v-model="user.lastName"
							:rules="[rules.required]"
							required></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field 
							label="名" 
							v-model="user.firstName"
							:rules="[rules.required]"
							required></v-text-field>                  
					</v-col>

					<v-col
						cols="12">
						<v-text-field 
							label="メールアドレス"
							v-model="user.email"
							:rules="[rules.required, rules.email]"
							required></v-text-field>
					</v-col>
					<v-col
					cols="12">
						<v-select
							label="問い合わせ種別" 
							:items="items" 
							v-model="form.subject"
							:rules="[rules.required]"
							required></v-select>
						</v-col>
						<v-col
						cols="12">           
						<v-textarea
							label="問い合わせ内容"
							v-model="form.message" 
							auto-grow
							:rules="[rules.required]"
							required></v-textarea>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>			
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="light-blue darken-4" 
				dark
				tile
				@click="send">
				送信</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import axios from 'axios'

export default {
	name: 'EmailTab',
	data: () => ({
		form: {
			messesage: null,
			subject: null
		},
		items: [
			{text: '予約確認・変更', value: '01_予約確認・変更'},
			{text: '忘れ物・手荷物破損', value: '02_忘れ物・手荷物破損'},
			{text: '当日の発着案内', value: '03_発着案内'},
			{text: 'WEB操作にお困りの方', value: '04_WEB操作'},
			{text: 'その他', value: '05_その他'}
		],
		rules: {
			required: v => !!v || '必須',
			email: v => /.+@.+\..+/.test(v) || 'メールアドレスが不正です。',
		}
	}),
	methods: {
    async send() {
			if(this.$refs.form.validate()) {
				let data = {
					lastname: this.user.lastName,
					firstname: this.user.firstName,
					email: this.user.email,
					subject: this.form.subject,
					message: this.form.message
				}
				let res = await axios.post('https://asia-northeast1-airgenesys.cloudfunctions.net/sendMail', data);
				console.log(res);
				if(res.data == 'success') {
					this.$store.state.snackbar.show = true
					this.$store.state.snackbar.text = 'メール送信完了'
				}
			}
    },
	},
	computed: {
		user() {
			return this.$store.state.user;
		}
	}
}
</script>