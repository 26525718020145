<template>
  <div class="text-center">
    <v-snackbar
      tile
      dark
      color="primary"
      v-model="snackbar.show"
      multi-line
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.show = false"
        >
          閉じる
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    name: 'Snackbar',
    data: () => ({
    }),
    computed: {
      snackbar() {
        return this.$store.state.snackbar
      }
    }
  }
</script>