<template>
	<v-card
	class="pa-5">
		<v-card-title
		class="justify-center">24時間受付</v-card-title>
		<v-card-text>
			<v-form
			ref="form"
			lazy-validation>
				<v-row>
					<v-col
					cols="6">
						<v-text-field
							label="姓" 
							v-model="user.lastName"
							:rules="[rules.required]"
							required></v-text-field>
					</v-col>
					<v-col cols="6">
						<v-text-field 
							label="名" 
							v-model="user.firstName"
							:rules="[rules.required]"
							required></v-text-field>                  
					</v-col>

					<v-col
					cols="12"
					lg="4"
					md="4">
						<v-text-field
							label="電話番号"
							v-model="user.phoneNumber"
							:rules="[rules.required, rules.phonenumber]"
							hint="市外局番含む、ハイフン、カッコ不要"
							required></v-text-field>
					</v-col>
					<v-col
					cols="6"
					lg="4"
					md="4">
						<v-menu
								ref="menuDate"
								v-model="menuDate"
								:close-on-content-click="false"
								:return-value.sync="form.date"
								transition="scale-transition"
								offset-y
								min-width="290px">
							<template v-slot:activator="{on}">
								<v-text-field
									v-model="form.date"
									:rules="[rules.required]"
									required
									label="希望日"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								locale="ja"
								v-model="form.date" 
								color="light-blue darken-4">
								<v-spacer></v-spacer>
								<v-btn color="light-blue darken-4" dark tile @click="$refs.menuDate.save(form.date)">OK</v-btn>
								<v-btn color="grey" dark tile @click="menuDate = false">キャンセル</v-btn>
							</v-date-picker>
						</v-menu>
					</v-col>

					<v-col
					cols="6"
					lg="4"
					md="4">
						<v-menu
								ref="menuTime"
								v-model="menuTime"
								:close-on-content-click="false"
								:return-value.sync="form.time"
								transition="scale-transition"
								offset-y
								min-width="290px">
							<template v-slot:activator="{on}">
								<v-text-field
										v-model="form.time"
										:rules="[rules.required]"
										required
										label="希望時間"
										v-on="on"
								></v-text-field>
							</template>
							<v-time-picker
								locale="ja"
								v-model="form.time"
								ampm-in-title
								color="light-blue darken-4">
								<v-spacer></v-spacer>
								<v-btn color="light-blue darken-4" dark tile @click="$refs.menuTime.save(form.time)">OK</v-btn>
								<v-btn color="grey" dark tile @click="menuTime = false">キャンセル</v-btn>
							</v-time-picker>
						</v-menu>
					</v-col>
					<v-col
					cols="12">
						<v-select
						label="問い合わせ種別" 
						:items="items" 
						v-model="form.subject"
						:rules="[rules.required]"
						required></v-select>            

						<v-textarea
							label="問い合わせ内容"
							v-model="form.message" 
							auto-grow></v-textarea>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>			
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="light-blue darken-4" 
				dark
				tile
				@click="callback">
				登録
				</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import axios from 'axios'

export default {
	name: 'EmailTab',
	data: () => ({
		form: {
			subject: null,
			message: null,
			date: null,
			time: null
		},
    menuDate: false,
		menuTime: false,
		rules: {
			required: v => !!v || '必須',
			phonenumber: v => /^\d{10,11}$/.test(v)  || '電話番号が不正です',
		},
		items: [
			{text: '予約確認・変更', value: '08fd9930-b53f-4105-b4ce-74c061725f6c'},
			{text: '忘れ物・手荷物破損', value: 'f0e285b2-9e6f-4741-bc2b-4994ee975629'},
			{text: '当日の発着案内', value: 'bda4d58e-57c6-45b6-8eaf-b02d18536772'},
			{text: 'WEB操作にお困りの方', value: '3d00a6bd-7f65-422f-8654-d97b9397ef41'},
			{text: 'その他', value: 'e0954472-0997-4f50-84e9-0599992166d6'}
		]
	}),
	methods: {
		//allowedStep: m => m % 10 === 0,
		async callback() {
			if (this.$refs.form.validate()) {
				console.log('callback form: ', this.form);
				let data = {
					lastname: this.user.lastName,
					firstname: this.user.firstName,
					number: this.user.phoneNumber,
					schedule: new Date(this.form.date + 'T' + this.form.time).toISOString(),
					subject: this.form.subject,
					message: this.form.message
				}
				//console.log(data);
				let res = await axios.post('https://asia-northeast1-airgenesys.cloudfunctions.net/postConversationsCallbacks', data);
				console.log(res);
				if(res.data == 'success') {
					this.$store.state.snackbar.show = true
					this.$store.state.snackbar.text = 'コールバック受付完了'
				}
			}
		}
	},
	computed: {
		user() {
			return this.$store.state.user
		}
	}
}
</script>