<template>
  <div class="register pa-5">
    <v-container>
      <v-row
      justify="center">
        <v-col
        cols="12"
        lg="8">
          <v-card
          tile
          class="pa-2"
          >
            <v-card-title>新規会員登録</v-card-title>
            <v-card-text>
              <v-form
              ref="form"
              v-model="valid"
              lazy-validation>
                <v-text-field
                  label="ユーザ名"
                  v-model="userName"
                  :rules="[rules.required, rules.username]">  
                </v-text-field>
                <v-text-field
                  label="姓"
                  v-model="lastName"
                  :rules="[rules.required]">  
                </v-text-field>
                <v-text-field
                  label="名"
                  v-model="firstName"
                  :rules="[rules.required]">  
                </v-text-field>
                <v-text-field
                  autocomplete="username"
                  label="メールアドレス"
                  v-model="email"
                  :rules="[rules.required, rules.email]">  
                </v-text-field>
                <v-text-field
                  class="cobrwose-masked"
                  autocomplete="current-password"
                  label="パスワード"
                  v-model="password"
                  data-cobrowse-masked="true"
                  :append-icon="isVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="isVisible ? 'text' : 'password'"
                  :error-messages="err"
                  :success-messages="success"
                  @click:append="isVisible = !isVisible">
                </v-text-field>              
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn 
              tile
              color="primary"
              dark
              @click="register">登録</v-btn>
              <v-btn
              tile
              color="secondary"
              dark
              @click="cancel">キャンセル</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import db from '@/firebase/init'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'Register',
  data: () => ({
    valid: true,
    userName: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    err: '',
    success: '',
    isVisible: false,
    rules: {
      required: v => !!v || '必須',
      min: v => v.length >= 8 || '最低8文字',
      username: v => /^[a-z0-9._-]{5,20}$/.test(v) || '5文字以上20文字以内、半角小文字英数字、「.」「-」「_」のみ可' ,
      orgname: v => /^[a-z0-9-]{3,50}$/.test(v) || 'Genesys Cloudの組織名、半角小文字英数字、及びハイフン' ,
      email: v => /.+@.+/.test(v) || 'メールアドレスが不正です。',
      emailMatch: () => ('The email and password you entered don\'t match'),
    },
  }),
  methods: {
    async register() {
      if (this.$refs.form.validate()) {
        let ref = db.collection('users').doc(this.userName)
        try {
          let doc = await ref.get();
          if (doc.exists) {
            this.err = 'このユーザ名は使用できません'
          } else {
            let data = await firebase.auth().createUserWithEmailAndPassword(this.email, this.password);
            await ref.set({
              lastName: this.lastName,
              firstName: this.firstName,
              email: data.user.email,
              userId: data.user.uid,
              userName: this.userName
            })
            this.success = 'アカウント登録が完了しました'
            this.$router.push({name: 'Home'})
          }
        } catch (err) {
          console.log(err)
          this.err = err.message
        }
      } else {
        this.err = '全ての情報を入力してください'
      }
    },
    cancel() {
      this.$refs.form.reset()
      this.$router.push({name: 'Home'})
    }
  }
}
</script>

<style scoped>
.register {
  width: 100vw;
  min-height: 100vh;
  background-image: url('/images/cover_03.jpg');
  background-size: cover;
  background-position: center center;
}
</style>