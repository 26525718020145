import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#FF4E02',
                success: '#23385d',
                secondary: '#4D5054'
            },
        },
    },
});
