<template>
  <v-speed-dial
    v-model="fab"
    bottom
    left
    fixed
    large
    transition="slide-y-reverse-transition"
    direction="top">
    <template v-slot:activator>
      <v-btn
      v-model="fab"
      color="primary"
      large
      dark
      fab>
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-face-agent
        </v-icon>
      </v-btn>
    </template>
    <v-btn
      fab
      dark
      color="indigo"
      @click="chat">
      <v-icon>mdi-chat</v-icon>
    </v-btn>

    <v-btn
      fab
      dark
      color="teal"
      to="/contact">
      <v-icon>mdi-email</v-icon>
    </v-btn>

    <v-btn
      fab
      dark
      color="purple"
      to="/contact">
      <v-icon>mdi-video-outline</v-icon>
    </v-btn>

    <screenshare-app />

    <cobrowse-app />

  </v-speed-dial>
</template>

<script>
// Load widgets-core after configuration object
// eslint-disable-next-line
CXBus.loadPlugin("widgets-core");
import Screenshare from '@/components/Screenshare'
import CoBrowse from '@/components/CoBrowse'

export default {
  name: 'Chat',
  components: {
    'screenshare-app': Screenshare,
    'cobrowse-app': CoBrowse
  },
  data: () => ({
    fab: false,
    autoSubmit: false
  }),
  methods: {
    chat() {
      // eslint-disable-next-line
      CXBus.command('WebChat.open', {
        form: {
          autoSubmit: this.isLoggedIn,
          lastname: this.user.lastName,
          firstname: this.user.firstName,
          email: this.user.email
        },
        userData: {
          phoneNumber: this.user.phoneNumber,
          user_id: this.user.userName,
          isLoggedIn: this.isLoggedIn
        },
      })
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isLoggedIn() {
      return this.$store.state.isLoggedIn
    }
  }
}
</script>