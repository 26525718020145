<template>
    <div class="profile">
      <v-container>
        <v-card
        flat>
          <v-card-title class="title">ログイン情報</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
              cols="12"
              lg="6"
              md="6">
                <v-text-field
                label="ユーザ名"
                v-model="profile.userName"
                disabled></v-text-field>
              </v-col>
              <v-col
              cols="12"
              lg="6"
              md="6">
                <v-text-field
                label="メールアドレス"
                v-model="profile.email"
                disabled></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card
        flat>
          <v-card-title class="title">会員情報</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
              cols="6">
                <v-text-field
                label="姓"
                v-model="profile.lastName"
                :disabled="edit"></v-text-field>
              </v-col>
              <v-col
              cols="6">
                <v-text-field
                label="名"
                v-model="profile.firstName"
                :disabled="edit"></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col
              cols="12"
              lg="6"
              md="6">
                <v-text-field
                label="電話番号"
                v-model="profile.phoneNumber"
                :disabled="edit"
                :rules="[rules.phoneNumber]"></v-text-field>
              </v-col>
              <v-col
              cols="12"
              lg="6"
              md="6">
                <v-menu
                  ref="menuDate"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :return-value.sync="profile.birthDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px">
                  <template v-slot:activator="{on}">
                    <v-text-field
                        v-model="profile.birthDate"
                        :rules="[rules.required]"
                        required
                        :disabled="edit"
                        label="生年月日"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="ja"
                    v-model="profile.birthDate"
                    color="success">
                    <v-spacer></v-spacer>
                    <v-btn color="light-blue darken-4" dark tile @click="$refs.menuDate.save(profile.birthDate)">OK</v-btn>
                    <v-btn color="grey" dark tile @click="menuDate = false">キャンセル</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col
              cols="12"
              lg="2">
                <v-text-field
                label="郵便番号"
                v-model="profile.postalCode"
                :disabled="edit"
                :rules="[rules.postalCode]"></v-text-field>
              </v-col>
              <v-col
              cols="12"
              lg="10">
                <v-text-field
                label="住所"
                v-model="profile.address"
                data-cobrowse-masked="true"
                :rules="[rules.required]"
                :disabled="edit"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-title class="title">クレジットカード情報</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
              cols="12"
              lg="4">
                <v-text-field
                label="名前"
                v-model="profile.cardName"
                :rules="[rules.card.name]"
                :disabled="edit"
                ></v-text-field>
              </v-col>
              <v-col
              cols="12"
              lg="4">
                <v-text-field
                label="クレジットカード番号"
                v-model="profile.cardNumber"
                data-cobrowse-masked="true"
                :rules="[rules.card.number]"
                :disabled="edit"
                ></v-text-field>
              </v-col>
              <v-col
              cols="6"
              lg="2">
                <v-text-field
                label="有効期限"
                v-model="profile.cardExpire"
                data-cobrowse-masked="true"
                :rules="[rules.card.expire]"
                :disabled="edit"></v-text-field>
              </v-col>
              <v-col
              cols="6"
              lg="2">
                <v-text-field
                label="セキュリティコード"
                v-model="profile.cardSecurityCode"
                data-cobrowse-masked="true"
                type="password"
                :rules="[rules.card.securityCode]"
                :disabled="edit"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            tile
            dark
            color="success"
            v-if="edit"
            @click="edit = !edit">編集</v-btn>
            <v-btn
            tile
            dark
            color="success"
            v-if="!edit"
            @click="save">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </div>
</template>

<script>
import db from '@/firebase/init'

export default {
  name: 'Profile',
  data:() => ({
    edit: true,
    menuDate: false,
    rules: {
      required: v => !!v || '必須',
      postalCode: v => /^\d{7}$/.test(v)  || 'ハイフンなしで7桁',
      phoneNumber: v => /^\d{10,11}$/.test(v)  || '市外局番込みで10桁または11桁',
      card: {
        name: v => /^[A-Z ]+$/.test(v)  || 'アルファベット大文字',
        number: v => /^\d{16}$/.test(v)  || 'ハイフンなし16桁',
        expire: v => /^(0[1-9]|1[0-2])\/?([0-9]{2})$/.test(v)  || 'MM/YY',
        securityCode: v => /^\d{3}$/.test(v)  || '3桁'
      }
    }
  }),
  methods: {
    async save() {
      //console.log(this.profile)
      try {
        await db.collection('users').doc(this.profile.userName).update({
          lastName: this.profile.lastName,
          firstName: this.profile.firstName,
          phoneNumber: this.profile.phoneNumber,
          birthDate: this.profile.birthDate,
          postalCode: this.profile.postalCode,
          address: this.profile.address,
          cardName: this.profile.cardName,
          cardNumber: this.profile.cardNumber,
          cardExpire: this.profile.cardExpire,
          cardSecurityCode: this.profile.cardSecurityCode
        });
        this.edit = !this.edit
      } catch (err) {
        console.error(err);
      }
    }
  },
  computed: {
    profile() {
      console.log(this.$store.state.user)
      return this.$store.state.user
    }
  }
}
</script>