<template>
  <div class="login pa-5">
    <v-container>
      <v-row
      justify="end">
        <v-col
        cols="12"
        lg="3">
          <v-card
          tile
          class="pa-2"
          >
            <v-card-title>ログイン</v-card-title>
            <v-card-text>
              <v-form
              ref="form"
              v-model="valid"
              lazy-validation>
                <v-text-field
                  autocomplete="username"
                  label="メールアドレス"
                  v-model="email"
                  :rules="[rules.required, rules.email]">  
                </v-text-field>
                <v-text-field
                  autocomplete="current-password"
                  label="パスワード"
                  v-model="password"
                  :append-icon="isVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="isVisible ? 'text' : 'password'"
                  @click:append="isVisible = !isVisible"
                  data-cobrowse-masked="true"
                  :error-messages="err"
                  :success-messages="success">
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn 
              block
              tile
              color="success"
              dark
              @click="login">ログイン</v-btn>
            </v-card-actions>
            <v-card-actions>
              <v-btn 
              block
              tile
              color="primary"
              dark
              :to="{name: 'Register' }">新規会員登録</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

export default {
  name: 'Login',
  data: () => ({
    valid: true,
    email: '',
    password: '',
    err: '',
    success: '',
    isVisible: false,
    rules: {
      required: v => !!v || '必須.',
      min: v => v.length >= 8 || '最小文字数は8文字です',
      email: v => /.+@.+/.test(v) || 'メールアドレス形式で入力して下さい',
      emailMatch: () => ('メールアドレスとパスワードが一致しません'),
    },
  }),
  methods: {
    async login() {
      if(this.$refs.form.validate()) {
        try {
          await firebase.auth().signInWithEmailAndPassword(this.email, this.password);
          this.$router.push({name: 'Home'})
        } catch (err) {
          this.err = err.message
        }
      } else {
        this.err = 'メールアドレスとパスワードを入力してください'
      }
    }
  }
}
</script>

<style scoped>
.login {
  width: 100%;
  min-height: 100vh;
  background-image: url('/images/cover_02.jpg');
  background-size: cover;
    background-position: center center;
}
</style>