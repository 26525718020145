<template>
  <div class="home hero">
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  }
}
</script>

<style scoped>
.hero {
    width: 100%;
    min-height: 100vh;
    background-image: url('/images/cover_01.jpg');
    background-size: cover;
    background-position: center center;
}
</style>